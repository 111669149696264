<template>
  <div class="component-wrap">
    <div class="banner" id="pageTop"></div>
    <div class="header">
      <div class="crumbs">
        {{ $t("practice.current_location")
        }}<span>{{ practiceEnum[practiceType + wrongType] }}</span>
      </div>
      <a-button type="primary" style="margin: -3px 0" @click="back">
        {{ $t("go_back") }}
      </a-button>
    </div>
    <a-spin :spinning="spinning">
      <div class="content-wrap">
        <div class="question" v-if="currentQue">
          <div class="question-top">
            <h6>{{ questionTypeEnum[currentQue.questionType] }}</h6>
            <span
              ><i>{{ currentIndex + 1 }}</i
              >/{{ dataInfo.questions.length }}</span
            >
          </div>
          <template v-if="[1, 3].includes(currentQue.questionType)">
            <div class="question-tit">{{ currentQue.title }}</div>
            <template v-if="currentQue.titimgs && currentQue.titimgs.length">
              <exam-media :mediaList="currentQue.titimgs" />
            </template>
            <div class="question-opt">
              <a-radio-group
                v-model:value="currentQue.userAnswer"
                :disabled="!!currentQue.isOver || !!wrongType"
                @change="grtUserAnswer"
              >
                <a-radio
                  class="opt-radio"
                  v-model:value="opt.id"
                  v-for="(opt, optIndex) in currentQue.options"
                  :key="opt.id"
                >
                  <div
                    :class="[
                      'opt-item',
                      {
                        right:
                          (currentQue.isOver || wrongType) && opt.isCorrect,
                      },
                      {
                        error:
                          (currentQue.isOver || wrongType) && !opt.isCorrect,
                      },
                    ]"
                  >
                    <div class="letter">{{ letterOpts[optIndex] }}</div>
                    <div class="info">
                      <span class="text">{{
                        opt.title || "&nbsp;&nbsp;"
                      }}</span>
                      <exam-media
                        v-if="opt.img"
                        type="option"
                        :mediaList="[opt.img]"
                      />
                    </div>
                  </div>
                </a-radio>
              </a-radio-group>
            </div>
          </template>
          <template v-else-if="currentQue.questionType == 2">
            <div class="question-tit">{{ currentQue.title }}</div>
            <template v-if="currentQue.titimgs && currentQue.titimgs.length">
              <exam-media :mediaList="currentQue.titimgs" />
            </template>
            <div class="question-opt">
              <a-checkbox-group
                v-model:value="currentQue.userAnswer"
                :disabled="!!currentQue.isOver || !!wrongType"
                @change="grtUserAnswer"
              >
                <a-checkbox
                  class="opt-checkbox"
                  :value="opt.id"
                  v-for="(opt, optIndex) in currentQue.options"
                  :key="opt.id"
                >
                  <div
                    :class="[
                      'opt-item',
                      {
                        right:
                          (currentQue.isOver || wrongType) && opt.isCorrect,
                      },
                      {
                        error:
                          (currentQue.isOver || wrongType) && !opt.isCorrect,
                      },
                    ]"
                  >
                    <div class="letter">{{ letterOpts[optIndex] }}</div>
                    <div class="info">
                      <span class="text">{{
                        opt.title || "&nbsp;&nbsp;"
                      }}</span>
                      <exam-media
                        v-if="opt.img"
                        type="option"
                        :mediaList="[opt.img]"
                      />
                    </div>
                  </div>
                </a-checkbox>
              </a-checkbox-group>
            </div>
          </template>
          <template v-else-if="currentQue.questionType == 4">
            <div class="question-tit">
              <template
                v-for="(opt, optIndex) in currentQue.options"
                :key="opt.id"
              >
                <span>{{ currentQue.titles[optIndex] }}</span>
                <a-tooltip trigger="focus" v-if="!wrongType">
                  <template #title>{{ opt.title }}</template>
                  <input
                    :class="[
                      'opt-input',
                      {
                        right:
                          (currentQue.isOver || wrongType) &&
                          opt.title &&
                          currentQue.strAnswer2[optIndex] &&
                          currentQue.strAnswer2[optIndex].indexOf(opt.title) !=
                            -1,
                      },
                      {
                        error:
                          (currentQue.isOver || wrongType) &&
                          (!(
                            currentQue.strAnswer2[optIndex] &&
                            currentQue.strAnswer2[optIndex].indexOf(
                              opt.title
                            ) != -1
                          ) ||
                            opt.title == ''),
                      },
                    ]"
                    v-model="opt.title"
                    @input="grtUserAnswer"
                  />
                </a-tooltip>
                <span
                  v-else
                  :class="[
                    'opt-input text',
                    {
                      right:
                        (currentQue.isOver || wrongType) &&
                        opt.title &&
                        currentQue.strAnswer2[optIndex] &&
                        currentQue.strAnswer2[optIndex].indexOf(opt.title) !=
                          -1,
                    },
                    {
                      error:
                        (currentQue.isOver || wrongType) &&
                        (!(
                          currentQue.strAnswer2[optIndex] &&
                          currentQue.strAnswer2[optIndex].indexOf(opt.title) !=
                            -1
                        ) ||
                          opt.title == ''),
                    },
                  ]"
                  >{{ opt.title }}</span
                >
              </template>
            </div>
            <template v-if="currentQue.titimgs && currentQue.titimgs.length">
              <exam-media :mediaList="currentQue.titimgs" />
            </template>
          </template>
          <div
            class="question-tips-ctrl"
            v-if="currentQue.analysis && !currentQue.isOver && wrongType !== 2"
          >
            <div class="btn" @click="showTips = !showTips">
              <img src="@/assets/image/practice/tips.png" alt="" />
              {{
                showTips ? $t("practice.hide_tips") : $t("practice.view_tips")
              }}
            </div>
          </div>
          <div class="error-number" v-if="wrongType === 2">
            {{ $t("practice.error_times") }}：{{ currentQue.failNum
            }}{{ $t("practice.number_of_times") }}
          </div>
          <div
            class="error-remove"
            v-if="wrongType === 0 && currentQue.isCorrect && !currentQue.isDel"
          >
            <div class="btn" @click="failRemove">
              <img src="@/assets/image/practice/wrong-book.png" alt="" />
              {{ $t("practice.transfer_incorrect_question_set") }}
            </div>
          </div>
          <div class="question-btn" v-if="currentQue.isDoing || wrongType">
            <a-button
              type="primary"
              @click="toNext(-1)"
              v-if="currentQue.isOver || wrongType"
            >
              {{
                $t(
                  currentIndex + 1 == dataInfo.questions.length
                    ? "XB_Finish"
                    : "LB_Exam_NestQuestion"
                )
              }}
            </a-button>
            <a-button
              type="primary"
              :loading="submitLoading"
              @click="confirm"
              v-else
            >
              {{
                $t(
                  currentIndex + 1 == dataInfo.questions.length
                    ? "XB_Finish"
                    : "CM_Confirm"
                )
              }}
            </a-button>
          </div>
        </div>
        <div class="explain" v-if="showTips">
          <h5>{{ $t("CM_LB_Prompt") }}</h5>
          <p v-if="currentQue.questionType == 4">{{ currentQue.analysis }}</p>
          <p v-else>{{ currentQue.analysis }}</p>
        </div>
        <div
          class="explain"
          v-if="currentQue && (currentQue.isOver || wrongType)"
        >
          <h5>{{ $t("practice.answer_analysis") }}</h5>
          <p>
            <template v-if="currentQue.questionType == 4">
              {{ $t("Lab_Lesson_RightKey") }}：<span
                class="right-opt"
                v-for="(oItem, oIndex) in currentQue.strAnswer2"
                :key="oIndex"
              >
                {{ oIndex + 1 }}、<span>{{
                  oItem.replaceAll("$", " / ")
                }}</span>
                &nbsp;
              </span>
            </template>
            <template v-else>
              {{ $t("Lab_Lesson_RightKey") }}：<template
                v-for="(opt, optIndex) in currentQue.options"
              >
                <span
                  class="right-opt"
                  :key="opt.id"
                  v-if="currentQue.strAnswer2.includes(opt.id)"
                  >{{ letterOpts[optIndex] }}</span
                >
              </template>
            </template>
          </p>
          <p>{{ currentQue.analysis }}</p>
          <analysis-media
            v-if="currentQue.analysisimgs"
            :data="currentQue.analysisimgs"
          />
        </div>
        <div class="answer-sheet" v-if="dataInfo.questions.length">
          <div class="btn" @click="showSheet = true">
            <div class="img">
              <img src="@/assets/image/practice/answer-sheet.png" alt="" />
            </div>
            <span>{{ $t("LB_AnswerSheet") }}</span>
          </div>
        </div>

        <a-empty
          v-if="!dataInfo.questions.length && !spinning"
          :image="require('@/assets/image/no_data_3.png')"
          :image-style="{ height: '186px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span style="color: #999">{{ $t("practice.no_quesition") }}</span>
          </template>
        </a-empty>

        <a-modal
          v-model:visible="showSheet"
          width="584px"
          :bodyStyle="{ padding: '40px 4px 4px 20px' }"
          title=""
          :footer="null"
        >
          <div class="sheet">
            <div class="sheet-head" v-if="!wrongType">
              <h5>{{ $t("LB_AnswerSheet") }}</h5>
              <!-- 答题卡 -->
              <div class="block ed"><span></span>{{ $t("CM_Answer") }}</div>
              <!-- 已答 -->
              <div class="block no"><span></span>{{ $t("CM_NoAnswer") }}</div>
              <!-- 未答 -->
            </div>
            <div class="sheet-number">{{ $t("practice.question_number") }}</div>
            <div class="sheet-list">
              <div
                class="item"
                v-for="(item, queIndex) in dataInfo.questions"
                :key="item.questionId"
                :class="{
                  checked: item.isOver,
                  current: queIndex == currentIndex,
                }"
                @click="toNext(queIndex)"
              >
                {{ queIndex + 1 }}
              </div>
            </div>
          </div>
        </a-modal>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, createVNode } from "vue";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";

import {
  practiceConfig,
  dailyQuestions,
  interestQuestions,
  failQuestions,
  failDetail,
  failDel,
  practiceSubmit,
  practiceHistory,
} from "@/api/practice";

import ls from "@/utils/local-storage";
import { STORAGE_TOKEN_KEY } from "@/store/mutation-type";
import { letterOpts, questionTypeEnum } from "@/utils/business";

import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import examMedia from "@/views/exam/exam-media.vue";
import analysisMedia from "@/views/exam/analysis-media.vue";
export default {
  components: {
    examMedia,
    analysisMedia,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      hasMusic: false,
      spinning: true,
      practiceType: Number(route.query.type || 0),
      wrongType: Number(route.query.wrong || 0), // 1查看错题 0错题练习 2高频错题
      isHistory: Number(route.query.isHistory || 0) == 1,
      isReload: false,
      folderIds: [],
      labelIds: [],
      practiceEnum: {
        1: proxy.$t("practice.mode.daily"),
        2: proxy.$t("practice.mode.interest"),
        3: proxy.$t("practice.mode.simulation"),
        4: proxy.$t("practice.mode.self"),
        5: proxy.$t("practice.wrong_question_practice"),
        6: proxy.$t("practice.view_wrong_question"),
        7: proxy.$t("practice.frequent_wrong_questions"),
      },
      dataInfo: {
        questions: [],
      },
      currentIndex: 0,
      currentQue: null,
      showTips: false,
      submitLoading: false,
      showSheet: false,
    });

    if (route.query.folderIds) {
      state.folderIds = route.query.folderIds
        .split(",")
        .map((num) => Number(num));
    }

    if (route.query.labelIds) {
      state.labelIds = route.query.labelIds.split(",");
    }

    const newPractice = ls.get("newPractice");
    ls.remove("newPractice");

    let isComplete = false;
    if (state.wrongType) isComplete = true;

    practiceConfig().then((res) => {
      state.hasMusic = res.data.wrongSoundPc == 1;
    });

    const back = () => {
      router.go(-1);
    };

    const lsToken = ls.get(STORAGE_TOKEN_KEY);
    const endSubmit = () => {
      if (
        state.dataInfo.questions.length &&
        !isComplete &&
        state.practiceType !== 5
      ) {
        fetch(
          process.env.VUE_APP_API_BASE_URL +
            `study/practice/${state.dataInfo.submitId}/${state.currentQue.questionId}/end`,
          {
            method: "get",
            headers: {
              Authorization: "token " + lsToken,
            },
            keepalive: true,
          }
        );
      }
    };

    window.addEventListener("beforeunload", function (e) {
      endSubmit();
    });

    onBeforeRouteLeave((to, from, next) => {
      if (!isComplete) {
        Modal.confirm({
          title: proxy.$t("practice.exit_confirmation"),
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            endSubmit();
            next();
          },
        });
      } else {
        next();
      }
    });

    const shuffle = (array) => {
      var m = array.length,
        t,
        i;
      while (m) {
        i = Math.floor(Math.random() * m--);
        t = array[m];
        array[m] = array[i];
        array[i] = t;
      }
      return array;
    };

    const initCorrectAnswers = (item) => {
      item.options.map((o, oIndex) => {
        if (item.questionType == 4 && item.strAnswer2[oIndex]) {
          if (item.strAnswer2[oIndex].split("$").includes(o.title)) {
            o.isCorrect = true;
          }
        } else {
          if (item.strAnswer2.includes(o.id)) {
            o.isCorrect = true;
          }
        }
      });
    };

    const getDetail = () => {
      let action = null;
      let params = {};
      if (state.isHistory || state.isReload) {
        if (state.practiceType == 1) {
          action = practiceHistory;
          params = { type: 1 };
        } else if (state.practiceType == 2) {
          action = practiceHistory;
          params = { type: 2 };
        }
      } else {
        if (state.practiceType == 1) {
          action = dailyQuestions;
          params = { ids: state.folderIds };
        } else if (state.practiceType == 2) {
          action = interestQuestions;
          params = { categoryIds: state.folderIds, id: state.labelIds };
        } else if (state.practiceType == 5 && state.wrongType === 1) {
          action = failDetail;
          params = { id: 0 };
        } else if (state.practiceType == 5 && state.wrongType === 0) {
          action = failQuestions;
        } else if (state.practiceType == 5 && state.wrongType === 2) {
          action = failDetail;
          params = { id: -1 };
        }
      }
      state.spinning = true;
      action(params).then((res) => {
        state.spinning = false;
        if (res.ret === 0) {
          state.dataInfo = res.data;
          state.dataInfo.questions = state.dataInfo.questions || [];
          if (state.dataInfo.questions.length > 0) {
            let lastQuestionId = res.data.lastQuestionId || 0;
            let lastQuestionIndex = 0;
            state.dataInfo.questions.map((item, index) => {
              item.strAnswer2 = JSON.parse(item.strAnswer);
              if (state.wrongType) {
                if (item.questionType == 1 || item.questionType == 3) {
                  item.userAnswer = "";
                  item.options.forEach((opt) => {
                    if (opt.isRight) item.userAnswer = opt.id;
                  });
                } else if (item.questionType == 2) {
                  item.userAnswer = [];
                  item.options.forEach((opt) => {
                    if (opt.isRight) item.userAnswer.push(opt.id);
                  });
                }
              } else {
                if (state.isHistory || state.isReload) {
                  item.answers = item.answers || [];
                } else {
                  item.answers = [];
                }
              }
              item.options = item.options || [];
              if (item.questionType == 4) {
                item.titles = item.title.split("＿");
                if (!item.options.length) {
                  for (var i = 0; i < item.titles.length - 1; i++) {
                    item.options.push({ id: i, title: "", isRight: false });
                  }
                }
              } else {
                if (item.optionRandom == 1) {
                  item.options = shuffle(item.options);
                }
              }
              if (state.isHistory || state.isReload) {
                if (item.answers.length) {
                  item.isDoing = true;
                  item.isOver = true;
                }
              }
              initCorrectAnswers(item);
              if (item.questionId == lastQuestionId) lastQuestionIndex = index;
            });
            state.currentIndex = lastQuestionIndex;
            state.currentQue = state.dataInfo.questions[state.currentIndex];
          }
        } else {
          isComplete = true;
        }
      });
    };

    if (newPractice || state.isHistory || state.practiceType == 5) {
      getDetail();
    } else {
      Modal.confirm({
        title: proxy.$t("practice.do_you_want_to_continue_answering_question"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: proxy.$t("cm_yes"),
        onOk() {
          state.isReload = true;
          getDetail();
        },
        cancelText: proxy.$t("cm_no"),
        onCancel() {
          getDetail();
        },
      });
    }

    const grtUserAnswer = () => {
      setTimeout(() => {
        let currentQue = state.currentQue;
        if ([1, 3].includes(currentQue.questionType)) {
          if (currentQue.userAnswer || currentQue.userAnswer === 0) {
            currentQue.isDoing = true;
            currentQue.options.map((item) => {
              item.isRight = false;
              if (item.id == currentQue.userAnswer) {
                item.isRight = true;
              }
            });
            currentQue.answers = [currentQue.userAnswer];
          } else {
            currentQue.isDoing = false;
          }
        } else if (currentQue.questionType == 2) {
          if (currentQue.userAnswer && currentQue.userAnswer.length >= 2) {
            currentQue.isDoing = true;
            currentQue.options.map((item) => {
              item.isRight = false;
              if (currentQue.userAnswer.includes(item.id)) {
                item.isRight = true;
              }
            });
            currentQue.answers = JSON.parse(
              JSON.stringify(currentQue.userAnswer)
            );
          } else {
            currentQue.isDoing = false;
          }
        } else if (currentQue.questionType == 4) {
          currentQue.isDoing = false;
          currentQue.userAnswer = [];
          currentQue.options.map((arr) => {
            if (arr.title.trim() === "") {
              arr.isRight = false;
            } else {
              currentQue.isDoing = true;
              currentQue.userAnswer.push(arr.id);
              arr.isRight = true;
            }
          });
          currentQue.answers = currentQue.userAnswer;
        }
      }, 100);
    };

    const failRemove = () => {
      Modal.confirm({
        title: proxy.$t("practice.remove_tip1"),
        icon: createVNode(ExclamationCircleOutlined),
        content: proxy.$t("practice.remove_tip2"),
        onOk() {
          state.currentQue.isDel = true;
          failDel(state.currentQue.questionId).then((res) => {
            if (res.ret == 0) {
              proxy.$message.success(proxy.$t("practice.remove_success"));
            }
          });
        },
      });
    };

    const soundPlay = (isRight) => {
      let sound = new Audio();
      if (isRight) {
        sound.src = require("@/assets/mp3/right.mp3");
      } else {
        sound.src = require("@/assets/mp3/error.mp3");
      }
      sound.play();
    };

    const toResult = () => {
      isComplete = true;
      router.replace({
        path: "/mine/practice/result",
        query: {
          type: state.practiceType,
          submitId: state.dataInfo.submitId,
          folderIds: state.folderIds.join(),
          labelIds: state.labelIds.join(),
          isHistory: state.isHistory ? 1 : "",
        },
      });
    };

    const toNext = (index) => {
      state.showSheet = false;
      if (
        state.currentIndex + 1 == state.dataInfo.questions.length &&
        index == -1
      ) {
        if (state.wrongType) {
          back();
        } else {
          let surplus = 0;
          state.dataInfo.questions.forEach((item) => {
            if (!item.isOver) surplus++;
          });
          if (surplus) {
            proxy.$message.warning(
              proxy.$t("practice.question_unanswered", [surplus])
            );
          } else {
            toResult();
          }
        }
      } else {
        if (index != -1) {
          state.currentIndex = index;
        } else {
          state.currentIndex++;
        }
        state.currentQue = state.dataInfo.questions[state.currentIndex];
        setTimeout(() => {
          document.getElementById("pageTop").scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 0);
      }
    };

    const confirm = () => {
      state.showTips = false;
      state.currentQue.isOver = true;
      state.submitLoading = true;
      state.currentQue.submitId = state.dataInfo.submitId;
      practiceSubmit(state.currentQue).then((res) => {
        state.submitLoading = false;
        if (res.ret == 0) {
          if (state.hasMusic) soundPlay(res.data);
          state.currentQue.isCorrect = res.data;
          if (res.data && state.practiceType != 5) {
            toNext(-1);
          }
        }
      });
    };

    return {
      questionTypeEnum,
      letterOpts,
      ...toRefs(state),
      grtUserAnswer,
      failRemove,
      toNext,
      confirm,
      back,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  min-height: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
}
.banner {
  width: 100%;
  height: 200px;
  background-image: url("~@/assets/image/practice/banner.png");
  background-size: 100% 100%;
  border-radius: 8px 8px 0 0;
}
.header {
  margin: 0 24px;
  padding: 24px 0;
  line-height: 26px;
  .mixinFlex(space-between; center);
  .crumbs {
    font-size: 14px;
    color: #999;
    span {
      color: @color-theme;
    }
  }
}
.content-wrap {
  min-height: 200px;
  margin: 0 24px;
  position: relative;
  .question {
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 0 10px rgba(148, 148, 148, 0.15);
    padding: 16px;
    &-top {
      .mixinFlex(space-between; center);
      line-height: 26px;
      margin-bottom: 24px;
      h6 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
      }
      span {
        font-size: 14px;
        i {
          font-size: 18px;
          font-weight: 600;
          color: @color-theme;
        }
      }
    }
    &-tit {
      font-size: 16px;
      line-height: 26px;
      color: #333;
      margin-bottom: 24px;
    }
    .opt-input {
      margin: 0 5px;
      border: none;
      border-bottom: 1px solid #202020;
      padding: 1px 4px;
      text-align: center;
      &.text {
        padding: 1px 16px;
      }
      &.right {
        border-color: #2ec45a;
      }
      &.error {
        border-color: #e82f2e;
      }
    }
    &-opt {
      .ant-radio-group,
      .ant-checkbox-group {
        width: 100%;
        .mixinFlex(space-between);
        flex-wrap: wrap;
      }
      .opt-checkbox,
      .opt-radio {
        .mixinFlex(flex-start; center);
        white-space: normal !important;
        width: 49%;
        margin-right: 0;
        ::v-deep(.ant-checkbox),
        ::v-deep(.ant-radio) {
          display: none;
          width: 0px;
          height: 0px;
          overflow: hidden;
        }
        ::v-deep(span.ant-radio + *),
        ::v-deep(.ant-checkbox + span) {
          padding-right: 0;
          padding-left: 0;
          width: 100%;
          height: 100%;
        }
        .opt-item {
          .mixinFlex(flex-start; flex-start);
          font-size: 16px;
          margin-bottom: 16px;
          border-radius: 4px;
          background: #fafcfe;
          border: 1px solid #ebebeb;
          padding: 12px;
          height: calc(100% - 16px);

          &.right {
            background-color: #eafeee;
            border-color: #2ec45a;
          }
          &.error {
            background-color: #feefef;
            border-color: #e82f2e;
          }
          ::v-deep(.opt-media-wrap) {
            margin-left: -40px;
          }
          .letter {
            width: 28px;
            height: 28px;
            .mixinFlex(center; center);
            margin-right: 12px;
            border: 1px solid #dddddd;
          }
          .info {
            width: calc(100% - 40px);
          }
          .text {
            color: #202020;
            line-height: 28px;
            max-width: calc(100% - 48px);
          }
        }
        &.ant-checkbox-wrapper-checked,
        &.ant-radio-wrapper-checked {
          .letter {
            border-color: #266fff;
            color: #fff;
            background-color: #266fff;
          }
          .text {
            color: #266fff;
          }
        }
      }
      .ant-radio-wrapper {
        .letter {
          border-radius: 50%;
        }
      }
      .ant-checkbox-wrapper {
        .letter {
          border-radius: 4px;
        }
      }
      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
      }
    }
    &-tips-ctrl {
      .mixinFlex(flex-end);
      .btn {
        .mixinFlex(flex-start; center);
        color: #377dff;
        cursor: pointer;
        user-select: none;
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
    }
    .error-number {
      font-size: 18px;
      line-height: 26px;
      color: #e82f2e;
      text-align: center;
      margin: 32px 0 8px;
    }
    .error-remove {
      .mixinFlex(flex-end);
      .btn {
        .mixinFlex(flex-start; center);
        color: #377dff;
        cursor: pointer;
        user-select: none;
        img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
    }
    &-btn {
      padding-top: 24px;
      text-align: center;
    }
  }
  .explain {
    h5 {
      margin: 24px 0 16px;
      font-size: 18px;
      font-weight: 600;
      line-height: 18px;
      border-left: 6px solid @color-theme;
      padding-left: 8px;
    }
    p {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 26px;
      word-break: break-all;
      .right-opt {
        padding-right: 8px;
        color: #22cf33;
      }
    }
  }
  .answer-sheet {
    width: 80px;
    position: absolute;
    right: 16px;
    .btn {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0 2px 10px rgba(148, 148, 148, 0.15);
      .mixinFlex(center; center);
      flex-direction: column;
      cursor: pointer;
      position: fixed;
      bottom: 150px;
      .img {
        .mixinImgWrap(28px; 28px);
      }
      span {
        font-size: 12px;
        line-height: 17px;
        color: #377dff;
        margin-top: 2px;
      }
    }
  }
}
.sheet {
  background-color: #fff;
  &-head {
    .mixinFlex(flex-start; center);
    margin-bottom: 20px;
    h5 {
      font-size: 16px;
      color: #333;
      line-height: 24px;
      margin-bottom: 0;
      font-weight: 600;
      margin-right: 24px;
    }
    .block {
      .mixinFlex(flex-start; center);
      margin-right: 24px;
      span {
        width: 16px;
        height: 16px;
        margin-right: 4px;
        border-radius: 2px;
      }
      &.ed {
        span {
          border: 1px solid #447dff;
          background-color: #447dff;
        }
      }
      &.no {
        span {
          border: 1px solid #999999;
        }
      }
    }
  }
  &-number {
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    margin-bottom: 16px;
  }
  &-list {
    max-height: 500px;
    overflow-y: auto;
    .item {
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 2px;
      border: 1px solid #999999;
      cursor: pointer;
      margin-right: 16px;
      margin-bottom: 16px;
      color: #999999;
      font-size: 18px;
      .mixinFlex(center; center);
      display: inline-flex;
      &:nth-child(10n) {
        margin-right: 0;
      }
      &.checked {
        border-color: #447dff;
        background-color: #447dff;
        color: #fff;
      }
      &.current {
        border-color: rgba(68, 125, 255, 0.2);
        color: #447dff;
        background-color: rgba(68, 125, 255, 0.2);
      }
    }
  }
}
</style>
